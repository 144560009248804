import { Auth } from "aws-amplify";

export default class AuthenticationService {
  async login(email, password) {
    return new Promise((resolve, reject) => {
      try {
        const user = Auth.signIn(email, password);
        console.log("user", user);
        resolve(user);
      } catch (error) {
        reject(error);
      }
    });
  }
  async createUser(email, password, phone_number) {
    return new Promise((resolve, reject) => {
      try {
        Auth.signUp({
          username: phone_number,
          password: password,
          attributes: {
            email: email,          // optional
            phone_number: phone_number,
            zoneinfo:'true'   // optional - E.164 number convention
            // other custom attributes 
          }
        }).then((data) => {
          console.log(data);
          resolve("success");
        })
          .catch((err) => {
            console.log(err)
          });

      } catch (error) {
        alert(error);
      }
    });
  }
  async changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      try {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            const changeInfo = Auth.changePassword(
              user,
              oldPassword,
              newPassword
            );
            console.log("changeInfo", changeInfo);
            resolve(changeInfo);
          })
          .then((data) => console.log(data))
          .catch((err) => console.log(err));
      } catch (error) {
        alert(error);
      }
    });
  }
  async forgotPassword(username) {
    return new Promise((resolve, reject) => {
      try {
        Auth.forgotPassword(username)
          .then((data) => {
            console.log(data);
            resolve(data);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        alert(error);
      }
    });
  }
  async forgotPasswordWithCode(username, code, new_password) {
    return new Promise((resolve, reject) => {
      try {
        Auth.forgotPasswordSubmit(username, code, new_password)
          .then(data => {
            console.log(data);
            resolve(data);
          })
          .catch(err => console.log(err));
      } catch (error) {
        alert(error);
      }
    });
  }
}
